import React, { Component } from 'react'
import CustomTheme from '../components/CustomTheme'
import {
    Grid,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem
} from '@mui/material'
import AppNavigator from '../components/AppNavigator'
import SendPanel from './ChatRoom/SendPanel'
import ChatWindow from './ChatRoom/ChatWindow'
import BotHelper from '../helpers/BotHelper'

const { innerWidth, innerHeight } = window

class ChatRoom extends Component {
    constructor(props) {
        super(props)
        this.state={
            inputMessage: "",
            messageThread: [],
            chatbotVersion: '',
            loading: false
        }
        this.updateMessage = this.updateMessage.bind(this)
        this.addMessage = this.addMessage.bind(this)
    }

    async componentDidMount()
    {
        let chatbotVersionsArr = process.env.REACT_APP_CHAT_BOT_VERSIONS.split(',')
        // console.log("chatbot arr", chatbotVersionsArr)
        let select = {}
        chatbotVersionsArr.map((v, i) => {
            select[i] = v
        })
        this.setState({
            chatbotVersion: 0,
            chatbotSelect: select
        })
    }

    async updateMessage(value) {
        // alert(value)
        this.addMessage(value, "user")
        let self = this
        setTimeout(() => {
            self.setState({
                loading: true
            })
        }, 1000)

        const botAnswer = await BotHelper.queryBot(value, this.state.chatbotVersion)
        // console.log("bot answered", botAnswer)
        this.setState({
            loading: false
        })
        if(botAnswer?.response)
        {
            return this.addMessage(botAnswer?.response, "bot")
        }
        return
        // this.setState({
        //     inputMessage: value
        // })
    }
    addMessage(msg, src)
    {
        let mutedMsg = [...this.state.messageThread]
        mutedMsg.push({
            message: msg,
            source: src
        })
        this.setState({
            messageThread: mutedMsg
        })
    }
    render() {
        // console.log("env variables", process.env)

        const { messageThread, chatbotVersion, chatbotSelect, loading } = this.state

        return <CustomTheme>
            <AppNavigator 
                chatbotVersion={chatbotVersion}
                chatbotSelect={chatbotSelect}
                handleSelectChatbot={(i) => this.setState({ chatbotVersion: i })}
                onReset={() => this.setState({
                    messageThread: []
                })}
            >
            <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-end"
                sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                    bgcolor: 'background.gray',
                    // minHeight: '70vh',
                    // maxHeight: '70vh',
                    minHeight: `${innerHeight - 56 - 72}px`,
                    maxHeight: `${innerHeight - 56 - 72}px`,
                    // py: 2
                    // height: '100vh'
                }}
            >
                <ChatWindow 
                    messageArr={messageThread}
                    loading={loading}
                />
            </Grid>
            <SendPanel
                sendMessage={this.updateMessage}
            />
            </AppNavigator>
        </CustomTheme>
    }
}

export default ChatRoom