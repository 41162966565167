import React from 'react'
import AvatarImage from '../../assets/images/Avatar.svg'
import NurseAvatarImage from '../../assets/images/Nurse_avatar.png'

const Avatar = props => (
    <img
        {...props}
        src={AvatarImage}
        alt="avatar-image" 
    />
)

const NurseAvatar = props => (
    <img
        {...props}
        src={NurseAvatarImage}
        alt="nurse-avatar-image" 
    />
)


export {
    Avatar,
    NurseAvatar
}