import React, { Component, useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem
} from '@mui/material'
import * as Loaders from '../../components/UIComponents/Loader'
import * as Icons from '../../components/UIComponents/Icons'
import MUIIconButton from '@mui/material/IconButton'
import ChatInput from '../../components/ChatInput'

const SendPanel = ({
    sendMessage,
    onMessageSent
}) => {
    const [message, updateMessage] = useState("")
    const [loading, setLoading] = useState(false)

    return (<React.Fragment>
        <Grid container direction={'row'} justify={"center"} align={"center"} sx={{
            position: 'absolute',
            maxHeight: {
                xs: 72,
                sm: 72,
                md: 72
            },
            minHeight: 72,
            px: {
                xs: 0
            },
            mb: 0,
            bottom: 0,
            boxShadow: 6
        }}>
            <Grid item xs sm md sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                // px: 1,
                maxHeight: 64,
                my: 'auto'
            }}>
                <form
                    style={{
                        width: '100%'
                    }}
                    onSubmit={(e) => {
                        e.preventDefault()
                        sendMessage(message)
                        updateMessage("")
                    }}
                >
                    <ChatInput
                        value={message}
                        type={"text"}
                        placeholder={"what's in your mind today..."}
                        onChange={e => {
                            // console.log(e)
                            updateMessage(e.target.value)
                        }}
                    />
                </form>
            </Grid>
            <Grid item
                sx={{
                    width: 56,
                    my: 'auto'
                }}
            >
                <MUIIconButton color="primary" aria-label="send the supportive message"
                    sx={{
                        height: 56,
                        width: 56
                    }}
                    onClick={() => {
                        sendMessage(message)
                        updateMessage("")
                    }}
                >
                    <Icons.SendIcon style={{ width: '100%' }} />
                </MUIIconButton>
            </Grid>
        </Grid>
    </React.Fragment>)
    
}

export default SendPanel