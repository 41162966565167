import PS2Logo from '../../assets/icons/Logo.svg'
import Id from '../../assets/icons/id.gif'
import Story from '../../assets/icons/book.gif'
import Messenger from '../../assets/icons/social-media.gif'
import HugsCount from '../../assets/icons/hug.svg'
import SupportMsg from '../../assets/icons/message.svg'
import SupportMsg2 from '../../assets/icons/message2.svg'
import Send from '../../assets/icons/send.svg'
import Hugged from '../../assets/icons/hugged.svg'
import Connected from '../../assets/icons/connected.svg'
import Delete from '../../assets/icons/delete.svg'
import Logout from '../../assets/icons/logout.svg'
import Home from '../../assets/icons/home.svg'
import LogoWhiteImage from '../../assets/icons/Logo_white.svg'
import FormIconImage from '../../assets/icons/form_icon.svg'

const FormIcon = props => <img {...props} src={FormIconImage} alt="form-icon" />

const Logo = (props) => <img {...props} src={PS2Logo} alt="app-logo" />
const LogoWhite = props => <img {...props} src={LogoWhiteImage} alt="app-logo-white" />
const LogoutIcon = props => <img {...props} src={Logout} alt="logout-icon" />
const HomeIcon = props => <img {...props} src={Home} alt="home-icon" />

const ProfileCardIcon = (props) => <img {...props} src={Id} alt="profile-tab-icon" />
const StoryIcon = props => <img {...props} src={Story}  alt="story-tab-icon" />
const MessengerIcon = props => <img {...props} src={Messenger}  alt="messenger-tab-icon" />

const HugsCountIcon = props => <img {...props} src={HugsCount}  alt="hugs-count-icon" />
const SupportMsgIcon1 = props => <img {...props} src={SupportMsg}  alt="support-message-1-icon" />
const SupportMsgIcon2 = props => <img {...props} src={SupportMsg2}  alt="support-message-2-icon" />

const HuggedCountIcon = props => <img {...props} src={Hugged}  alt="hugged-icon" />
const ConnectedIcon = props => <img {...props} src={Connected}  alt="connected--icon" />

const SendIcon = props => <img {...props} src={Send}  alt="send-icon" />
const DeleteIcon = props => <img {...props} src={Delete}  alt="delete-icon" />

export {
    FormIcon,
    Logo,
    LogoutIcon,
    HomeIcon,
    LogoWhite,
    ProfileCardIcon,
    StoryIcon,
    MessengerIcon,
    HugsCountIcon,
    SupportMsgIcon1,
    SupportMsgIcon2,
    SendIcon,
    HuggedCountIcon,
    ConnectedIcon,
    DeleteIcon
}