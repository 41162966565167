class BotHelper {
    constructor() {}
    
    async queryBot(message = "", bot = 0)
    {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "text": message
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      let botVersion=process.env.REACT_APP_CHAT_BOT_ENDPOINT.split(',')
      let queryBotType = botVersion[bot]
      
      const r = await fetch(`${process.env.REACT_APP_API_HOST}/${queryBotType}`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => false);
      return r
    }

    async resetBot()
    {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      let botVersion=process.env.REACT_APP_CHAT_BOT_ENDPOINT.split(',')
      let queryBotType = botVersion[0]

      
      const r = await fetch(`${process.env.REACT_APP_API_HOST}/nttrefresh`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => false)
      return r

    }
}

export default new BotHelper()