import React, { Component, useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem,
    Avatar
} from '@mui/material'
import * as Loaders from '../../components/UIComponents/Loader'
import * as Icons from '../../components/UIComponents/Icons'
import * as Texts from '../../components/UIComponents/Text'
import * as Images from '../../components/UIComponents/Images'
import AlwaysScrollToBottom from '../../components/AlwaysScrollToBottom'

const ChatWindow = ({
    messageArr,
    loading
}) => {

    if(!messageArr || messageArr.length <= 0)
    {
        return <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
                width: 1,
                flexGrow: 1,
                px: 2
            }}
        >
            <Box>
                <Images.NurseAvatar 
                    style={{
                        opacity: .8
                    }}
                />
            </Box>
            <Box>
                <Texts.Paragraph>
                    Have any questions? I am here to help.
                </Texts.Paragraph>
            </Box>
        </Stack>
    }

    let renderMessage = []
    messageArr.forEach((m, i) => {
        if(m?.source === "user")
        {
            return renderMessage.push(<UserBubble 
                key={`chat-bubble-${i}`}
                message={m?.message}
            />)
        }
        return renderMessage.push(<BotBubble 
            key={`chat-bubble-${i}`}
            message={m?.message}
        />)
    })

    return <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{
                width: 1,
                px: 2,
                // bgcolor: 'secondary.main',
                pb: 3
            }}
        >
            {renderMessage}
            {loading && <BotLoading />}
            <AlwaysScrollToBottom />
    </Stack>
    

}

const BotBubble = ({
    idx,
    message,
    timestamp
}) => {
    return (<Stack
        direction="row"
        justifyContent={"flex-start"}
        sx={{
            width: 1,
            ml: 2,
            mt: 2
        }}
    >
        <Box>
            <Avatar sx={{
                boxShadow: 2
            }} alt="Nurse Bot" src={require("../../assets/images/Nurse_avatar.png")} />
        </Box>
        <Box
            sx={{
                maxWidth: .6,
                bgcolor: 'background.dark',
                borderRadius: 2,
                p: 2,
                ml: 1
            }}
        >
            <Texts.Paragraph
                sx={{
                    color: 'primary.contrastText'
                }}
            >
                {message}
            </Texts.Paragraph>
        </Box>
    </Stack>)
}

// let sentence = "."

const BotLoading = ({
    
}) => {
    const [sentence, updateSentence] = useState(".")

    useEffect(() => {

        let textingItv = setInterval(() => {
            console.log("interval")
            if(sentence.length < 3)
            {
                let newSentence = sentence+"."
                updateSentence(newSentence)
                return
            }
            updateSentence(".")
            return
        }, 1000)

        return () => {
            clearInterval(textingItv)
        };
    }, [sentence])

    return (<Stack
        direction="row"
        justifyContent={"flex-start"}
        sx={{
            width: 1,
            ml: 2,
            mt: 2
        }}
    >
        <Box>
            <Avatar sx={{
                boxShadow: 2
            }} alt="Nurse Bot" src={require("../../assets/images/Nurse_avatar.png")} />
        </Box>
        <Box
            sx={{
                maxWidth: .6,
                bgcolor: 'background.dark',
                borderRadius: 2,
                p: 2,
                ml: 1
            }}
        >
                        <Texts.Paragraph
                    sx={{
                        color: 'primary.contrastText'
                    }}
                >
                    {sentence}
                </Texts.Paragraph>
        </Box>
    </Stack>)
}

const UserBubble = ({
    idx, 
    message, 
    timestamp
}) => {
    return (
        <Stack
            direction="row"
            justifyContent={"flex-end"}
            sx={{
                width: 1,
                // mr: 2,
                mt: 2
            }}
        >
            <Box
                sx={{
                    maxWidth: .6,
                    bgcolor: 'background.default',
                    borderRadius: 2,
                    boxShadow: 2,
                    p: 2,
                    ml: 1
                }}
            >
                <Texts.Paragraph
                    sx={{
                        color: 'primary.dark'
                    }}
                >
                    {message}
                </Texts.Paragraph>
            </Box>
        </Stack>)
}

export default ChatWindow