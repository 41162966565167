import React from 'react'
import CustomTheme from '../CustomTheme'
import Typography from '@mui/material/Typography'

const H1 = React.forwardRef((props, ref) => {
    return(
        <CustomTheme>
        <Typography
            id={props.id} 
            ref={ref} 
            variant="h1"
            {...props}
        >
            {props.children}
        </Typography>
        </CustomTheme>
    )
})

const H2 = React.forwardRef((props, ref) => {
    return(
        <CustomTheme>
        <Typography 
            id={props.id} 
            ref={ref} 
            variant="h2"
            {...props} 
        >
            {props.children}
        </Typography>
        </CustomTheme>
    )
})

const H3 = React.forwardRef((props, ref) => {
    return(
        <CustomTheme>
        <Typography 
            id={props.id} 
            ref={ref} 
            variant="h3"  
            {...props} 
        >
            {props.children}
        </Typography>
        </CustomTheme>
    )
})

const H4 = React.forwardRef((props, ref) => {
    return(
        <CustomTheme>
        <Typography 
            id={props.id} 
            ref={ref} 
            variant="h4"
            {...props} 
        >
            {props.children}
        </Typography>
        </CustomTheme>
    )
})


const H5 = React.forwardRef((props, ref) => {
    return(
        <CustomTheme>
        <Typography 
            id={props.id} 
            ref={ref} 
            variant="h5"
            {...props}
        >
            {props.children}
        </Typography>
        </CustomTheme>
    )
})


const Tip = React.forwardRef((props, ref) => {
    return(
        <p 
            id={props.id} 
            ref={ref} 
            onClick={props.onClick !== null ? props.onClick:null}
            variant="subtitle2"
            {...props}
        >
            {props.children}
        </p>
    )
})

const Text = React.forwardRef((props, ref) => {
    return(
        <CustomTheme>
        <Typography 
            id={props.id} 
            ref={ref}
            variant="body1"
            sx={{
                color: 'primary.main'
            }}
            {...props}
        >
            {props.children}
        </Typography>
        </CustomTheme>
    )
})

const Paragraph = React.forwardRef((props, ref) => {
    return(
        <CustomTheme>
            <Typography 
                id={props.id} 
                ref={ref} 
                className={props.className} 
                sx={{
                    color: 'primary.dark'
                }}
                variant="body2"
                {...props}
            >
                {props.children}
            </Typography>
        {/*<article id={props.id} ref={ref}>
                <p className={`fyw-paragraph ${props.className}`} style={props.style}>
                    {props.children}
                </p>
        </article>*/}
        </CustomTheme>
    )
})

const Title = React.forwardRef((props, ref) => {
    return(
        <Typography
            variant="h4"
            id={props.id} 
            ref={ref}
            {...props}
        >
            {props.children}
        </Typography>
    )
})

const Subtitle = React.forwardRef((props, ref) => {
    return(
        <Typography
            variant="h4" 
            id={props.id} 
            ref={ref}
            {...props}
        >
            {props.children}
        </Typography>
    )
})

export { Tip, Text, Title, Subtitle, Paragraph, H1, H2, H3, H4, H5 }