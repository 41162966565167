// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'

const ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#113766',
      dark: '#002843',
      contrastText: '#E4F5FF',
      light: '#457c9c',
    },
    secondary: {
      main: '#50d2c1',
      light: '#88fff4',
      dark: '#00a091',
      contrastText: '#000000',
    },
    call2action: {
      main: '#F9DC5C',
      light: '#ffff8d',
      dark: '#c3ab29',
      contrastText: '#000000',
    },
    error: {
      main: '#ED254E',
    },
    info: {
      main: '#1F3232',
    },
    text: {
      primary: '#113766',
      secondary: '#465362',
      light: '#F5FBFF'
    },
    background: {
      default: '#ffffff',
      gray: '#F5FBFF',
      highlight: '#F9DC5C',
      dark: '#002843',
    },
    divider: '#528586',
    warning: {
      main: '#F9DC5C',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'Open Sans',
      'PT Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  drawerWidth: 250,
  questionnaireTitleWidth: 320,
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#689f38',
        color: '#fff',
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
    MuiTooltip: {
      arrow: true,
    },
  },
}

const theme = createTheme(ThemeOptions)

const CustomTheme = props => <ThemeProvider theme={theme}>
    {props.children}
</ThemeProvider>

export default CustomTheme